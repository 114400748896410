import useSWR, { SWRConfiguration } from "swr";
import { useMemo } from "react";
import { getQuoteComponentFromPDP } from "../../components/public/Quote/getQuoteComponentFromPDP";
import { defaultFetcher } from "../HTTPClient";
import { APIV3, PaginationMeta } from "../HTTPClientV3";
import { JSONAPIDocument, serializers } from "../Serializer";
import { Redemption } from "./deal";
import {
  Item as TileItem,
  tileItemAPIFields,
  tileItemAPIIncludes,
} from "./items";
import { useUser } from "./user";
import { localStorageWrapper } from "../LocalStorage";
import { APIQueryParams, buildUrlWithQueryParams } from "../QueryParams";
import { ACCESS_TOKEN_UID } from "../constants";
import type { Field, Form } from "./Form";
import { useConfiguration } from "./configuration";
import type { FileSerialized } from "../../components/ui/FileUploader";

interface ItemData {
  id?: string;
  name: string;
}
interface ItemLink {
  id: string;
  text: string;
  url: string;
}
interface Customer {
  showcasedCustomerLogoUrl: string;
  showcasedCustomerName: string;
}
interface Media {
  id: string;
  url: string;
  filename: string;
}
interface Feature {
  featuredPriority: number;
  body: string;
  name: string;
}

export enum RedemptionType {
  Email = "email",
  Link = "landing_page",
}

export interface Discount {
  id?: string;
  title: string;
  activationType: RedemptionType;
  contactEmail: string;
  instructions: string;
  restrictions: string | null;
  averageTotalSavingsPrefix: "exact" | "up_to";
  averageTotalSavingsAmount: number;
  averageTotalSavingsCurrency: string;
  averageTotalSavingsNotes: string | null;
  slug: string;
  description: string;
  listing: string;
  categories: ItemData[];
  redemption: Redemption[];
  newCustomersOnly?: boolean | null;
  guestEmailVerification?: string;
}

interface EditionBenefit {
  id?: string;
  content: string;
  tooltip: string;
}

interface EditionIncludedItem {
  id?: string;
  unit: string;
  amount: string;
  unlimited: boolean;
}

interface EditionContractTerms {
  id?: string;
  currency?: string;
  amount?: string;
  minimumServiceLength: string;
}

export interface EditionCost {
  id?: string;
  currency: string;
  amount: string;
  minUnits?: number;
  maxUnits?: number;
  unit: "NOT_APPLICABLE" | "ONE_TIME_SETUP" | string;
}

export interface EditionPlan {
  id?: string;
  revenueType: "recurring" | "one_time" | "tiered" | "free";
  frequency?:
    | "hourly"
    | "daily"
    | "monthly"
    | "quarterly"
    | "semi_annually"
    | "annually"
    | "biennially"
    | "triennially";
  costs?: EditionCost[];
  contract?: EditionContractTerms;
}

export interface Edition {
  id?: string;
  title: string;
  description: string;
  buttonLabel: string;
  buttonRedirectUrl: string;
  mostPopular: boolean;
  items: EditionIncludedItem[];
  benefits: EditionBenefit[];
  plans: EditionPlan[];
}

export interface Item {
  id: string;
  name: string;
  description: string;
  contactName: string;
  slug: string;
  videoUrl: string | null;
  productRateAverage: number; // TODO: BE return this field and recommendations
  productTotalVotes: number; // TODO: BE return this field and recommendations
  howItWorks: string | null;
  collections: ItemData[];
  categories: ItemData[];
  listingFeatures: Feature[];
  listingEditions: Edition[];
  mediaFiles: Media[];
  partnerQuotesForm?: boolean;
  sellerQuotesForm?: boolean;
  seller: {
    id: string;
    name: string;
    logoUrl: string;
    symbolLogoUrl: string;
    website: string | null;
    description: string | null;
    facebook: string | null;
    instagram: string | null;
    linkedin: string | null;
    twitter: string | null;
  };
  deal?: Discount;
  quoteSetting?: QuoteSetting;
  claimRedirectUrl?: string;
  showcasedOrganizations?: Customer[];
  languages?: string[];
  helpfulLinks?: ItemLink[];
  endorsed?: boolean;
  blurredDeal?: Pick<
    Discount,
    | "title"
    | "averageTotalSavingsAmount"
    | "averageTotalSavingsPrefix"
    | "averageTotalSavingsCurrency"
    | "id"
  >;
  supportEmail: string | null;
  supportPhoneNumber: string | null;
  supportUrl: string | null;
  pricingLink?: string;
  communityVisibility?: {
    hidden: boolean;
  };
  installNowButton?: {
    url: string;
  };
}

const base = "/items";

const itemFieldsToInclude = [
  "collections",
  "categories",
  "listing_features",
  "deal.redemption",
  "quote_setting",
  "listing_editions",
  "listing_editions.plans",
  "listing_editions.plans.costs",
  "listing_editions.plans.contract",
  "listing_editions.benefits",
  "listing_editions.items",
  "media_files",
  "seller",
  "helpful_links",
  "showcased_organizations",
];

export function getItem(
  slug?: string,
  options?: { previewDealId?: string; includeQuoteForms?: boolean },
) {
  const quoteFields = options?.includeQuoteForms
    ? ["seller_quotes_form", "partner_quotes_form"]
    : [];

  const params = {
    no_track_analytics: "true",
    include: [...itemFieldsToInclude, ...quoteFields].toString(),
    "fields[item]": [
      "name",
      "description",
      "contact_name",
      "slug",
      "video_url",
      "product_rate_average",
      "product_total_votes",
      "how_it_works",
      "support_email",
      "support_phone_number",
      "support_url",
      "pricing_link",
      "endorsed",
      "languages",
      "claim_redirect_url",
      "collections",
      "categories",
      "listing_features",
      "listing_editions",
      "media_files",
      "seller",
      "deal",
      "quote_setting",
      "showcased_organizations",
      "helpful_links",
      "blurred_deal",
      "community_visibility",
      "install_now_button",
      ...quoteFields,
    ].join(),
    "fields[collections]": "name",
    "fields[categories]": "name",
    "fields[listing_feature]": ["featured_priority", "body", "name"].join(),
    "fields[listing_edition]": [
      "title",
      "description",
      "button_label",
      "button_redirect_url",
      "most_popular",
      "items",
      "benefits",
      "plans",
    ].join(),
    "fields[listing_edition_item]": ["unit", "amount", "unlimited"].join(),
    "fields[listing_edition_benefit]": ["content", "tooltip"].join(),
    "fields[listing_edition_plan]": [
      "frequency",
      "revenue_type",
      "costs",
      "contract",
    ].join(),
    "fields[listing_edition_plan_cost]": [
      "currency",
      "amount",
      "min_units",
      "max_units",
      "unit",
    ].join(),
    "fields[listing_edition_plan_contract]": [
      "minimum_service_length",
      "currency",
      "amount",
    ].join(),
    "fields[media_files]": "url,filename",
    "fields[seller]": [
      "name",
      "logo_url",
      "symbol_logo_url",
      "website",
      "description",
      "facebook",
      "instagram",
      "linkedin",
      "twitter",
    ].join(),
    "fields[deal]": [
      "title",
      "activation_type",
      "contact_email",
      "instructions",
      "restrictions",
      "average_total_savings_currency",
      "average_total_savings_prefix",
      "average_total_savings_amount",
      "average_total_savings_notes",
      "slug",
      "description",
      "listing",
      "categories",
      "redemption",
      "new_customers_only",
      "guest_email_verification",
    ].join(),
    "fields[quote]": ["title", "url", "instructions", "description"].join(),
    "fields[showcased_organizations]":
      "showcased_customer_logo_url,showcased_customer_name",
    "fields[helpful_links]": "text,url",
    "fields[blurred_deal]":
      "average_total_savings_prefix,average_total_savings_amount,average_total_savings_currency,title",
    "fields[community_visibility]": "hidden",
    "fields[install_now_button]": "url",
  };
  const url = options?.previewDealId
    ? buildUrlWithQueryParams(`${base}/${slug}`, {
        preview_deal_id: options?.previewDealId,
        ...params,
      })
    : buildUrlWithQueryParams(`${base}/${slug}`, {
        ...params,
      });
  const request = () => APIV3.get<JSONAPIDocument<Item>>(url);
  return {
    json: async () => {
      const { data } = await defaultFetcher(request, serializers.item);
      return data;
    },
    fallbackKey: `${base}/${slug || ""}/${
      localStorageWrapper.getItem(ACCESS_TOKEN_UID) || ""
    }`,
  };
}

export function useItem(slug?: string, previewId?: string) {
  const { configuration } = useConfiguration();
  const pdpQuoteComponent = useMemo(
    () => getQuoteComponentFromPDP(configuration),
    [configuration],
  );
  const { fallbackKey, json } = getItem(slug, {
    previewDealId: previewId,
    includeQuoteForms: !!pdpQuoteComponent,
  });
  const { loadingUser } = useUser();
  const { data, isValidating, mutate } = useSWR(
    slug && !loadingUser ? fallbackKey : null,
    json,
  );
  return {
    loadingItem: isValidating || loadingUser, // TODO: we dont need loadingUser to be false to show the item, but we need it for the redemption. This condition can be easily change when we have proper loaders for PDP
    item: data,
    setItem: mutate,
  };
}

const tileSectionParams: Partial<APIQueryParams> = {
  "page[number]": 1,
  "page[size]": 10,
};

type SimilarByOptions = "collection" | "category";
export function getSimilarTo(slug?: string, similarBy?: SimilarByOptions) {
  const url = buildUrlWithQueryParams(`${base}/${slug}/similar_items`, {
    include: tileItemAPIIncludes.include,
    ...tileSectionParams,
    ...tileItemAPIFields,
    similar_by: similarBy || "category",
  });
  const request = () => APIV3.get<JSONAPIDocument<TileItem[]>>(url);
  return {
    json: async () => {
      const { data } = await defaultFetcher(request, serializers.item);
      return data;
    },
    fallbackKey: url,
  };
}

export function useSimilarTo(slug?: string, similarBy?: SimilarByOptions) {
  const { fallbackKey, json } = getSimilarTo(slug, similarBy);
  const { loadingUser } = useUser();
  const { data, isValidating } = useSWR(slug ? fallbackKey : null, json);

  return {
    loadingSimilarItems: isValidating || loadingUser, // TODO: we dont need loadingUser to be false to show the item, but we need it for the redemption. This condition can be easily change when we have proper loaders for PDP
    similarItems: data,
  };
}

export interface QuoteSetting {
  id: string;
  description?: string;
  instructions?: string;
  title?: string;
  url: string;
}

export interface QuoteRequest {
  id: string;
  kind: "to_partner" | "to_seller";
  title: string;
  createdAt: string;
  buyerUser: {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
  };
  buyerOrganization: {
    id: string;
    name: string;
  };
  managerOrganization: {
    id: string;
    name: string;
  };
  formResponse: {
    id: string;
    fieldsResponses: Record<
      string,
      {
        label: string;
        value: string;
        dropdownLabel: string;
        filename: string;
        kind: Field["kind"];
      }
    >;
  };
}

type QuoteFor = "partner" | "seller";

export function getQuoteForm(slug?: string, createdFor?: QuoteFor) {
  const url = buildUrlWithQueryParams(`${base}/${slug}/quote_forms`, {
    created_for: createdFor,
    include: ["fields.options", "fields.rule", "fields.file"].join(),
    "fields[form]": ["title", "fields"].join(),
  });
  const request = () => APIV3.get<JSONAPIDocument<Form>>(url);
  return {
    json: async () => {
      const { data } = await defaultFetcher(request, serializers.form);
      return data;
    },
    fallbackKey: url,
  };
}

export function useQuoteForm(slug?: string, createdFor?: QuoteFor) {
  const { fallbackKey, json } = getQuoteForm(slug, createdFor);
  const { data, isValidating } = useSWR(
    slug && createdFor ? fallbackKey : null,
    json,
  );

  return {
    loadingQuoteForm: isValidating,
    quoteForm: data,
  };
}

export type PostHistoryFieldType = {
  field_id: number;
  value?: string | FileSerialized | null;
};

export function postQuoteHistory(
  slug: string,
  payload: {
    created_for: string;
    responses: PostHistoryFieldType[];
  },
) {
  const url = `${base}/${slug}/quote_requests`;
  const request = () =>
    APIV3.post<JSONAPIDocument<void>>(url, {
      body: JSON.stringify(serializers.quote_response.serialize(payload)),
    });
  return {
    json: async () => {
      const { data } = await defaultFetcher(
        request,
        serializers.quote_response,
      );
      return data;
    },
    fallbackKey: url,
  };
}

export const defaultPageSizeQuoteHistory = 15;
export function getQuoteHistory(slug?: string, page?: number) {
  const url = buildUrlWithQueryParams(`${base}/quote_requests`, {
    include: [
      "buyer_user",
      "buyer_organization",
      "manager_organization",
      "form_response",
    ].join(),
    "fields[buyer_user]": ["first_name", "last_name", "email"].join(),
    "fields[manager_organization]": "name",
    "fields[buyer_organization]": "name",
    "fields[form_response]": ["fields_responses"].join(),
    "fields[quote_request]": [
      "kind",
      "title",
      "created_at",
      "buyer_user",
      "manager_organization",
      "buyer_organization",
      "form_response",
    ].join(),
    "page[size]": defaultPageSizeQuoteHistory,
    ...(page ? { "page[number]": page } : {}),
    ...(slug ? { "filter[slug]": slug } : {}),
  });
  const request = () => APIV3.get<JSONAPIDocument<QuoteRequest[]>>(url);
  return {
    json: async () => {
      const { data, meta } = await defaultFetcher(
        request,
        serializers.quote_request,
      );
      return { data, meta: meta as PaginationMeta };
    },
    fallbackKey: url,
  };
}

export function getQuoteHistoryNoIncludes() {
  const url = buildUrlWithQueryParams(`${base}/quote_requests`, {
    include: "",
    "fields[quote_request]": "",
    "page[size]": 1,
  });
  const request = () =>
    APIV3.get<JSONAPIDocument<Pick<QuoteSetting, "id">[]>>(url);
  return {
    json: async () => {
      const { data } = await defaultFetcher(request, serializers.quote_setting);
      return data;
    },
    fallbackKey: url,
  };
}

export function useQuoteHistory(
  slug?: string,
  page?: number,
  options?: SWRConfiguration & { doRequest?: boolean },
) {
  const { fallbackKey, json } = getQuoteHistory(slug, page);
  const { data, isValidating, mutate } = useSWR(
    options?.doRequest !== false ? fallbackKey : null,
    json,
    options,
  );

  return {
    loadingQuoteHistory: isValidating,
    quoteHistory: data?.data,
    quoteHistoryMeta: data?.meta,
    setQuoteHistory: mutate,
  };
}

export function useQuoteHistoryAvailable(
  options?: SWRConfiguration & { doRequest?: boolean },
) {
  const { fallbackKey, json } = getQuoteHistoryNoIncludes();
  const { data, isValidating, mutate } = useSWR(
    options?.doRequest !== false ? fallbackKey : null,
    json,
    options,
  );

  return {
    loadingQuoteHistoryAvailable: isValidating,
    quoteHistoryAvailable: !!data?.length,
    setQuoteHistoryAvailable: mutate,
  };
}
