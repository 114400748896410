import { styled, Typography } from "@mui/material";
import { FunctionComponent, ReactNode } from "react";
import { getJustificationByAlignment } from "../../builder/themeOptionsBuilder";
import { Alignment } from "../../types/theme";

interface Props {
  alignment: Alignment;
  color: "warning" | "primary" | "success" | "error";
  icon: ReactNode;
  children: ReactNode;
  className?: string;
}

const Container = styled(Typography)<Pick<Props, "alignment">>(
  ({ alignment, color, theme }) => ({
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    marginTop: "1.5rem",
    gap: ".2rem",
    marginBottom: "1.5rem",
    justifyContent: getJustificationByAlignment(alignment),
    color: `${theme.palette[color as Props["color"]]?.main}!important`,
  }),
);

const Text = styled("span")(() => ({
  marginLeft: ".5rem",
  fontWeight: "bold",
  textTransform: "uppercase",
}));

const TextAlert: FunctionComponent<Props> = ({
  alignment,
  color,
  icon,
  children,
  className,
}: Props) => (
  <Container color={color} alignment={alignment} className={className}>
    {icon}
    <Text>{children}</Text>
  </Container>
);

export default TextAlert;
