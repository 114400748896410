import { FunctionComponent, PropsWithChildren } from "react";
import { Card, alpha, styled, useTheme } from "@mui/material";
import { useShadows } from "../../builder/Theme";

export type PanelVariant = "plain" | "elegant" | "sleek";

interface Props {
  variant?: PanelVariant;
  className?: string;
}

export function usePanelPadding(variant: PanelVariant) {
  return `${["sleek", "elegant"].includes(variant) ? "24px" : 0} !important`;
}

export function usePanelColor(variant: PanelVariant) {
  const theme = useTheme();
  return variant === "elegant"
    ? theme.palette.text.panelsDefault
    : theme.palette.text.default;
}

const StyledCard = styled(Card)<{
  customVariant: PanelVariant;
}>(({ theme, customVariant }) => {
  const { panels } = theme.settings;
  const boxShadow = useShadows(panels.shadows);
  const padding = usePanelPadding(customVariant);
  const color = usePanelColor(customVariant);
  const border =
    customVariant !== "plain"
      ? {
          borderColor: alpha(
            theme.palette.inputsUnderline.main,
            panels.border.opacity,
          ),
          borderStyle: panels.border.opacity > 0 ? "solid" : "none",
          borderWidth: `${panels.border.thickness}px`,
          borderRadius: panels.border.radius * 5,
        }
      : {
          borderRadius: 0,
        };

  return {
    display: "flex",
    flex: 1,
    padding,
    ...border,
    boxShadow: customVariant === "sleek" ? boxShadow : "none",
    color,
    "& .MuiTypography-root": {
      color,
    },
    backgroundColor:
      customVariant === "elegant"
        ? theme.palette.panelsBackground.default
        : "transparent",
  };
});

const Panel: FunctionComponent<PropsWithChildren<Props>> = ({
  children,
  className,
  variant = "plain",
}) => (
  <StyledCard customVariant={variant} elevation={0} className={className}>
    {children}
  </StyledCard>
);

export default Panel;
