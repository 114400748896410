import { FunctionComponent, ReactNode } from "react";
import { styled, Typography } from "@mui/material";

interface Props {
  children: ReactNode;
  size?: "large";
  spacing?: "none" | "medium" | "large";
  subtitle?: string;
}

export const sectionMargin = "1rem";

const StyledTitle = styled(Typography)<Pick<Props, "size">>(
  ({ theme, size }) => ({
    color: theme.palette.text.default,
    fontSize:
      size === "large"
        ? theme.typography.h2.fontSize
        : theme.typography.h5.fontSize,
    fontWeight: "bold",
  }),
);

const Subtitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.default,
  fontSize: 14,
  fontWeight: "initial",
}));

const Container = styled(Typography)<Pick<Props, "spacing">>(({
  theme,
  spacing,
}) => {
  const bottomSpacing: Record<
    Exclude<Props["spacing"], undefined>,
    number | string
  > = {
    none: 0,
    medium: theme.spacing(2),
    large: theme.spacing(4),
  };
  return {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(0.5),
    marginBottom: bottomSpacing[spacing || "medium"],
  };
});

const SectionTitle: FunctionComponent<Props> = ({
  children,
  subtitle,
  size,
  spacing,
}) => {
  if (!children && !subtitle) {
    return null;
  }

  return (
    <Container spacing={spacing}>
      {children && (
        <StyledTitle size={size} variant="h3">
          {children}
        </StyledTitle>
      )}
      {subtitle && <Subtitle variant="h4">{subtitle}</Subtitle>}
    </Container>
  );
};

export default SectionTitle;
