import type { Item } from "../../../dataAccess/api/item";
import type { Configuration } from "../../../dataAccess/api/configuration";
import type { QuoteSettings } from "./Quote";

export function getQuoteComponentFromPDP(configuration?: Configuration) {
  return configuration?.allowedFeatures?.includes("quotes")
    ? configuration?.publishedTemplate?.properties?.pages
        .find(({ id }: { id: string }) => id === "PDP")
        ?.components?.find(({ type }) => type === "DealDetailsLayout")
        ?.components?.find(({ type }) => type === "Quote")
    : undefined;
}

export function useRequestQuoteButtonConfig({
  configuration,
  item,
  settings,
}: {
  settings?: QuoteSettings;
  item?: Item;
  configuration?: Configuration;
}) {
  const { quoteSetting: quote } = item || {};
  const forBoth = settings?.enabledQuoteRecipient === "both";
  const forSellers = forBoth || settings?.enabledQuoteRecipient === "seller";
  const forPartners = forBoth || settings?.enabledQuoteRecipient === "partner";
  const sellerButtonEnabled = item?.sellerQuotesForm && forSellers;
  const partnerButtonEnabled = item?.partnerQuotesForm && forPartners;
  const url = `/${item?.slug}/quote`;
  const sellerNameReplaceConfig = {
    key: "seller_name",
    value: item?.seller?.name,
  } as const;
  const quotesEnabledInMarketplace =
    configuration?.allowedFeatures?.includes("quotes") &&
    quote &&
    (sellerButtonEnabled || partnerButtonEnabled);
  return {
    sellerButtonEnabled,
    partnerButtonEnabled,
    sellerNameReplaceConfig,
    quotesEnabledInMarketplace,
    url,
  };
}
